import React from 'react'
import Layout from '../layout/layout'

const Page = () => (
  <Layout>
    <section className="text-center">
        <div className="inner">
            <div className="text">
                <h1>Oops!</h1>
                <p>We can't seem to find the page you are looking for.</p>
            </div>
        </div>
    </section>
  </Layout>
)

export default Page
